<template>
  <div>
    <!-- INIT Terminal BOX -->
    <div
      id="terminalBox"
      class="terminalBox p-1 overflow-auto fade-out d-block"
      style="max-height: 400px"
    >
      <p>
        <span v-for="(item, index) in termMsg" v-bind:key="index">
          {{ item }} <br />
        </span>
      </p>
    </div>
    <!-- END Terminal BOX -->
  </div>
</template>


<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import PlannerService from "@/services/PlannerService";

export default {
  name: "Terminal",
  data() {
    return {
      termMsg: [],
      terminalBoxID: null,
      connWS: null
    };
  },
  watch: {
    v_availability: function(oldVal, newVal){
      //console.log(this.v_availability);
    },
    v_volumes: function(oldVal, newVal){
      //console.log(this.v_volumes);
    }
  },
  props: {},
  components: { },
  computed: {
    ...mapState("planner", [
      "v_selectedYear",
      "v_selectedCriteria",
      "v_uuid",
      "v_availability",
      "v_volumes",
      "v_selectedPlan",
      "v_readjustments",
      "v_transfers"
    ]),
  },
  methods: {
    ...mapActions("planner", ["v_updDataSet","v_updFullDataSet", "v_resetUUID", "v_setTaskIsRunning", "v_updSelectedPlan"]),
    /* Get CurrentTime formatted */
    formatMsg(message) {
      return (
        moment().format("HH:mm:ss") +
        " - " +
        moment().format("DD/MM/YYYY") +
        "  ::  " +
        message
      );
    },

    /* requestNewPlan */
    async requestNewPlan() {
      this.v_setTaskIsRunning(true);
      this.termMsg = []
      // Establecer socket para evitar el timoeut de 30 segundos por defecto
      await this.connWSHandler();

      /* Solicitamos al backend las cosas necesarias */
      let dataStruct = {
        uuid: this.v_uuid,
        year: this.v_selectedYear,
        criteria: this.v_selectedCriteria,
        availability: this.v_availability,
        volumes: this.v_volumes, 
        planTrasvases: this.v_transfers,
        planReajustes: this.v_readjustments
      };
      //console.log(dataStruct);

      // Add message
      this.termMsg.push(
        this.formatMsg(
          "Solicitando nueva planificación de aducción inteligente"
        )
      );
      PlannerService.runNewPlan(dataStruct).then(async (response)=>{
        this.termMsg.push(this.formatMsg(response.data));
        let data = response.data
        console.log(data)
        let arrayConvertido = new Array();
        for(let i=0; i<data.length; i++){
          arrayConvertido.push(JSON.parse(data[i]))
        }
        //console.log(arrayConvertido)

        this.v_updFullDataSet(arrayConvertido);
        /*this.v_updDataSet(arrayConvertido[0]);
        this.v_updSelectedPlan(0);*/
        await this.v_resetUUID();
        await this.connWSClose();
        await this.v_setTaskIsRunning(false);
        await this.$emit('closeRunPlanModal')
        await this.$router.push({ name: 'wizard-step-2'})
      }).catch((err)=>{
        console.log("Se ha generado algún error en el procesamiento o la solicitud: " + err);
        this.termMsg.push(this.formatMsg("No se puede generar el plan: Error del servidor"));
      });
      
    },

    /* Obtener la url principal */
    getWSBaseUrl() {
      let wsURL = null;

      if (process.env.VUE_APP_WEBSOCKET_URI) {
        wsURL = process.env.VUE_APP_WEBSOCKET_URI + "?uuid=" + this.v_uuid;
      } else {
        let loc = window.location,
          new_uri;
        if (loc.protocol === "https:") {
          new_uri = "wss:";
        } else {
          new_uri = "ws:";
        }
        new_uri += "//" + loc.host + "/websocket?uuid=" + this.v_uuid;
        wsURL = new_uri;
      }

      return wsURL;
    },

    /* Iniciar conexión Websocket */
    async connWSHandler() {
      this.terminalBoxID = await document.getElementById("terminalBox");
      this.connWS = new WebSocket(await this.getWSBaseUrl());

      // Listener para establecer conexión con el backend EN RTS
      this.connWS.onmessage = (event) => {
        this.termMsg.push(this.formatMsg(event.data));
        this.terminalBoxID.scrollTop = this.terminalBoxID.scrollHeight;
      };

      // Abrir el canal de conexion
      this.connWS.onopen = function (event) {
        // TODO HERE
      };
    },

    /* Resetear websocket */
    async connWSReset() {
      await this.connWS.close();
      await this.connWSHandler();
    },

    /* Cerrar websocket */
    async connWSClose() {
      await this.connWS.close();
    },
  },
  async mounted() {
    // Mensaje de inicio de aplicación
    this.termMsg.push(this.formatMsg("Herramienta a la espera de ejecución"));
  },
};
</script>

<style scoped>
.btnRunPlan {
  font-size: 0.9rem;
  font-weight: 500;
}

button {
  transform: scale(0.9);
}

.terminalBox {
  min-height: 300px;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  font-size: 0.9rem;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>