<template>
  <div class="form_holder d-none d-sm-block" id="stepSelector">
    <form id="msform">
      <ul id="progressbar">
        <!-- ponemos los > que para que se activen confomre avancemos -->
        <li
          class="cursor-pointer"
          :class="step > 0 ? 'active' : ''"
          @click="pushRoute('wizard-step-0')"
        >
          1. Nuevo plan
        </li>        
        <li
          class="cursor-pointer"
          :class="step > 0 ? 'active' : ''"
          @click="pushRoute('wizard-step-1')"
        >
          2. Configuración inicial
        </li>
        <li
          :class="step > 1 ? 'cursor-pointer active' : ''"
          @click="pushRoute('wizard-step-2')"
        >
          3. Seleccionar plan
        </li>
        <li
          :class="step > 2 ? 'cursor-pointer active' : ''"
          @click="pushRoute('wizard-step-3')"
        >
          4. Visualización
        </li>
      </ul>
    </form>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  components: {},
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState("planner", ["v_dataSet"]),
  },
  methods: {
    pushRoute(path) {
      this.$router.push({ name: path });
    },
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
#msform {
  width: 100%;
  margin: 50px auto;
  text-align: center;
  position: relative;
}
#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  position: absolute;
}
#msform fieldset:not(:first-of-type) {
  display: none;
}
#msform input,
#msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif !important;
  color: #2c3e50;
  font-size: 13px;
}
#msform .action-button {
  width: 100px;
  background: #4d79c6cf;
  font-weight: 400;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #4d79c6cf;
}
.fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 500;
}
.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
}
#progressbar li {
  list-style-type: none;
  color: #d2d2d2;
  text-transform: capitalize;
  font-size: 16px;
  width: 25%;
  float: left;
  position: relative;
}
#progressbar li.active {
  color: #4d79c6cf;
}
#progressbar li:before {
  content: ""; /*counter(step)*/
  counter-increment: step;
  width: 50px;
  line-height: 50px;
  display: block;
  font-size: 18px;
  /*color: #fff;*/
  background: transparent;
  border-radius: 100%;
  margin: 0 auto 5px auto;
  border: 2px solid #ececec;
}
#progressbar li:nth-child(1):before {
  content: "\f5fd";
  font-family: "FontAwesome";
}
#progressbar li:nth-child(2):before {
  content: "\f013";
  font-family: "FontAwesome";
}
#progressbar li:nth-child(3):before {
  content: "\f03a";
  font-family: "FontAwesome";
}
#progressbar li:nth-child(4):before {
  content: "\f00c";
  font-family: "FontAwesome";
}
#progressbar li:after {
  content: "";
  width: 73%;
  height: 2px;
  background: #ececec;
  position: absolute;
  left: -35%;
  top: 25px;
  z-index: -1;
}
#progressbar li:first-child:after {
  content: none;
}
#progressbar li.active:before {
  border: 2px solid #4d79c6cf;
  color: #4d79c6cf;
  /* content: "\f164";*/
}
#progressbar li.active:after {
  background: #4d79c6cf;
  color: white;
}
</style>