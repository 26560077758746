<template>
  <div class="mt-4 mb-4 p-4">
    <!-- Modal AGREGAR -->
    <Modal
      id="runPlanModal"
      title="Calculando plan de aducción solicitado"
      :frameless="true"
      :footer="false"
      :altmode="true"
      styleHeader="background: #305680; color: #fff;"
      styleBtnClose="filter: brightness(100) invert(1);"
      ref="runPlanModal"
    >
      <template v-slot:modalBody>
        <RunPlan ref="runPlan" id="new-runplan" :mode="'add'" @closeRunPlanModal="closeRunPlanModal"/>
      </template>
    </Modal>

    <!------ INIT SELECTOR ------------>
    <div class="d-flex justify-content-around" id="selectorStep">
      <!-- Primera opcion:: Retroceder -->
      <div>
        <button
          class="btn btn-primary"
          type="button"
          title="Retroceder"
          @click="previousStep"
          :disabled="previousBtn"       
        >
          <i class="fa-solid fa-circle-arrow-left me-3"></i>
          <span class="text-uppercase">Retroceder</span>
        </button>
      </div>
      <!-- Segunda opcion :: Avanzar -->
      <div  v-if="step != 1">
        <button
          class="btn btn-primary"
          type="button"
          title="Siguiente paso"
          @click="nextStep"
          :disabled="nextBtn"   
          v-if="step != 3"     
        >
          <span class="text-uppercase">Siguiente</span>
          <i class="fa-solid fa-circle-arrow-right ms-3"></i>
        </button>
      </div>
      <div v-else>
        <button
          class="btn btn-primary"
          type="button"
          title="Siguiente paso"
          @click="triggerNewPlan"
          data-bs-toggle="modal"
          data-bs-target="#runPlanModal"         
        >
          <span class="text-uppercase">Siguiente</span>
          <i class="fa-solid fa-circle-arrow-right ms-3"></i>
        </button>
      </div>      
    </div>
    <!------ END SELECTOR ------------>
  </div>
</template>

<script>
import Modal from "@/components/commons/Modal";
import RunPlan from "@/views/wizard/newPlan/step1-preconfigure/components/RunPlan";

export default {
  data() {
    return {
      nextBtn: false,
      previousBtn: false,
      nextUrlPath: "",
      previousUrlPath: "",
    };
  },
  props: {
    step: {
      type: Number,
    },
  },
  components: {
    Modal,
    RunPlan
  },
  computed: {},
  methods: {
    // Avanzar a la siguiente accion
    previousStep() {
      this.$router.push({ name: this.previousUrlPath });
    },
    // Retroceder a la accion anterior
    nextStep() {
      // Si estamos en el caso 1 disparamos el requestNewPlan
      this.$router.push({ name: this.nextUrlPath });
    },
    triggerNewPlan(){
      this.$refs.runPlan.requestNewPlan();
    },
    //Método para cerrar el modal de editar conexión
    closeRunPlanModal() {
      this.$refs.runPlanModal.closeModal();
    },    
    //Auto-Scroll al ir entre transiciones
    autoScroll(){
      // Este corresponde al id que sta en Steps-selector.vue
      let ele = document.getElementById("headerNavBar");

      ele.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      
    }
  },
  mounted() {
    switch (this.step) {
      // Si estamos en el paso 1
      case 1:
        this.previousBtn = false;
        this.nextBtn = false;
        this.nextUrlPath = "wizard-step-2";
        this.previousUrlPath = "wizard-step-0";
        break;
      // Si estamos en el paso 2
      case 2:
        this.previousBtn = false;
        this.nextBtn = false;
        this.nextUrlPath = "wizard-step-3";
        this.previousUrlPath = "wizard-step-1";
        break;
      // Si estamos en el paso 3
      case 3:
        this.previousBtn = false;
        this.nextBtn = true;
        this.previousUrlPath = "wizard-step-2";
        break;
    }
    this.autoScroll();
  },
};
</script>

<style scoped>
</style>